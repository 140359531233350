import {
  Box,
  Checkbox,
  TextField,
  Stack,
  Tab,
  Tabs,
  Typography,
  MenuItem,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Link,
  Button,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Logo } from '../logo';
import { PhrasesBox } from '../Phrases';
import {
  Add,
  CopyAllOutlined,
  CurrencyRuble,
  KeyboardArrowDown,
  Percent,
  Search,
  WarningAmberOutlined,
} from '@mui/icons-material';
import servicesData from './data';
import { calculateDiscountedPrice, copyToBuffer, numberToWords } from '../../helpers';
import { Pop } from './pop';
import { CalcSelect } from '../calc-select';
import { BackBtn } from '../back-btn';
import tarifs from './tarifs';

const flatList = Object.values(servicesData).flat();

const nameMap = new Map(flatList.map((item) => [item.id, item.full]));

const arr = Object.keys(servicesData);

const services = flatList.reduce((acc, service) => {
  if ('divider' in service) return acc;
  const { id, ...rest } = service;
  acc[id] = rest;
  return acc;
}, {});

function calculatePriceBySteps(documentCount, perStep) {
  const step = 20; // Количество документов для шага
  const pricePerStep = perStep; // Стоимость за шаг

  // Округляем количество документов в большую сторону до ближайшего шага
  const steps = Math.ceil(documentCount / step);

  // Рассчитываем стоимость
  const totalPrice = steps * pricePerStep;

  return totalPrice;
}

function calculatePriceForEmployees(employeeCount) {
  if (employeeCount <= 5) {
    return 900;
  } else if (employeeCount <= 10) {
    return 1300;
  } else if (employeeCount <= 15) {
    return 1500;
  } else if (employeeCount <= 20) {
    return 2000;
  } else {
    // Для количества сотрудников больше 20, шаг в 5 сотрудников добавляет по 500 ₽
    const extraEmployees = Math.ceil((employeeCount - 20) / 5);
    return 2000 + extraEmployees * 750;
  }
}
function calculatePriceForEmployeesBusiness(employeeCount) {
  if (employeeCount <= 10) {
    return 0;
  } else if (employeeCount <= 15) {
    return 500;
  } else if (employeeCount <= 20) {
    return 2000;
  } else {
    // Для количества сотрудников больше 20, шаг в 5 сотрудников добавляет по 500 ₽
    const extraEmployees = Math.ceil((employeeCount - 20) / 5);
    return 2000 + extraEmployees * 500;
  }
}

const calculateSpecialFreeQuantity = (item, selectedTariff, specials) => {
  const map = new Map(specials);
  let quantity;
  if (map.has(selectedTariff)) {
    const limit = map.get(selectedTariff);
    quantity = item.quantity <= limit ? 0 : item.quantity - limit;
  } else quantity = item.quantity;
  return quantity;
};

function calculateShedule(positions) {
  if (positions <= 10) {
    return 1000;
  } else if (positions <= 20) {
    return 2000;
  } else if (positions <= 30) {
    return 3000;
  } else if (positions <= 40) {
    return 4000;
  } else {
    return 4000 + Math.ceil((positions - 40) / 10) * 500;
  }
}
const calculateItem = (item, selectedTariff) => {
  if (+item.quantity === 0 || +item.quantity < 0) return 0;

  if (typeof item.tariff !== 'undefined') {
    return calculateDiscountedPrice(
      ((item.price * item.tariff) / 100) * item.quantity * item.coef,
      item.discount,
    );
  }

  if (typeof item.free !== 'undefined') {
    const quantity = calculateSpecialFreeQuantity(item, selectedTariff, item.free);
    return calculateDiscountedPrice(item.price * quantity * item.coef, item.discount);
  }

  switch (item.id) {
    case 'military-registration-docs': {
      if (selectedTariff === 'premium') {
        return 0;
      }
      let price = 3500;
      price += (item.quantity - 1) * 1500;
      return calculateDiscountedPrice(price * item.coef, item.discount);
    }
    case 'staff-schedule-changes': {
      if (['premium', 'business'].includes(selectedTariff)) {
        return 0;
      }
      return calculateDiscountedPrice(calculateShedule(item.quantity) * item.coef, item.discount);
    }
    case 'employee-data-corrections':
      if (selectedTariff === 'premium') return 0;
      if (selectedTariff === 'business')
        return calculateDiscountedPrice(
          calculatePriceForEmployeesBusiness(item.quantity) * item.coef,
          item.discount,
        );
      return calculateDiscountedPrice(
        calculatePriceForEmployees(item.quantity) * item.coef,
        item.discount,
      );
    case 'vat-refund-documents':
      const tariff = +item.price / 2;
      const summ = (+item.quantity / 100) * 5;
      let res;
      if (summ < tariff) {
        res = tariff;
      } else if (res >= 200_000) {
        res = 200_000;
      } else {
        res = summ;
      }
      return calculateDiscountedPrice(res * item.coef, item.discount);
    case 'debt-collection':
      if (selectedTariff === 'premium') return 0;
      return calculateDiscountedPrice(
        ((item.quantity * item.price) / 100) * item.coef,
        item.discount,
      );
    case 'accounting-for-branch':
      if (selectedTariff === 'premium') return 0;
      let price = item.price;
      price += (item.quantity - 1) * 2500;
      return calculateDiscountedPrice(price * item.coef, item.discount);

    case 'legal-primary-documents-input':
      return calculateDiscountedPrice(
        calculatePriceBySteps(item.quantity, item.price) * item.coef,
        item.discount,
      );
    case 'responses-to-government-requests':
      if (selectedTariff === 'premium' || (selectedTariff === 'business' && item.quantity < 4))
        return 0;
      if (selectedTariff === 'business') {
        const quantity = item.quantity - 3;
        return calculateDiscountedPrice(item.price * quantity * item.coef, item.discount);
      }
      return calculateDiscountedPrice(item.price * item.quantity * item.coef, item.discount);
    default:
      return calculateDiscountedPrice(item.price * item.quantity * item.coef, item.discount);
  }
};

const makeTotal = (selected, totalCoef, selectedTariff) => {
  return (
    Object.values(selected).reduce((acc, item) => {
      return acc + calculateItem(item, selectedTariff);
    }, 0) * totalCoef
  );
};

const checkTab = (arr, selected) => {
  const check = arr.reduce(
    (acc, service) => {
      if ('divider' in service) return acc;
      if (selected[service.id]?.quantity > 0) {
        acc.quantity += 1;
        acc.total += service.cost * selected[service.id].coef * selected[service.id].quantity;
      }
      return acc;
    },
    { quantity: 0, total: 0 },
  );
  return check;
};

function Dop({ phrases }) {
  const [tab, setTab] = useState(arr[0]);
  const [selectedTariff, setSelectedTariff] = useState(tarifs[0].id);
  const [selected, setSelected] = useState(
    flatList.reduce((acc, item) => {
      if (typeof item.divider === 'string') {
        return acc;
      }
      if (item.id === 'vat-refund-documents') {
        acc[item.id] = { price: 0, id: item.id, discount: 0, quantity: 0, coef: 1 };
        return acc;
      }
      acc[item.id] = {
        quantity: 0,
        coef: 1,
        discount: 0,
        price: item.costs?.[selectedTariff] || 0,
        id: item.id,
        tariff: item.tariffs?.[selectedTariff],
        free: item.free,
      };
      return acc;
    }, {}),
  );

  useEffect(() => {
    setSelected((prev) => {
      const newObj = { ...prev };
      Object.keys(prev).forEach((key) => {
        if (key === 'vat-refund-documents') return;
        const obj = services[key];
        const price = obj.costs?.[selectedTariff] || 0;
        newObj[key].price = price;
        if (obj.tariff) {
          const newTariff = obj.tariffs[selectedTariff];
          newObj[key].tariff = newTariff;
        }
      });
      return newObj;
    });
  }, [selectedTariff]);
  const [filter, setFiler] = useState('');
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalDifficult, setTotalDifficult] = useState(1);

  const hasFilter = !!filter;

  const list = useMemo(() => (hasFilter ? flatList : servicesData[tab]), [hasFilter, tab]);

  const res = useMemo(
    () =>
      calculateDiscountedPrice(makeTotal(selected, totalDifficult, selectedTariff), totalDiscount),
    [selected, totalDifficult, totalDiscount, selectedTariff],
  );

  const text = useMemo(() => {
    return `Перечень услуг на общую сумму ${res.toFixed(2)} (${numberToWords(res)} руб. ${
      res.toFixed(2).toString().split('.')[1]
    } коп)\r\n${Object.entries(selected)
      .filter(([, item]) => !!item.quantity)
      .reduce((acc, [key, value], i, arr) => {
        const total = calculateItem(value, selectedTariff);
        return (
          acc +
          (i + 1) +
          '. ' +
          nameMap.get(key) +
          ' - ' +
          calculateDiscountedPrice(total * totalDifficult, totalDiscount).toFixed(2) +
          `${i < arr.length - 1 ? ';' : '.'}` +
          '\r\n'
        );
      }, '')}`;
  }, [res, selected, selectedTariff, totalDifficult, totalDiscount]);

  const [animating, setAnimating] = useState(false);

  const timeout = useRef(null);

  useEffect(() => {
    if (!animating) return;
    timeout.current = setTimeout(() => {
      setAnimating(false);
    }, 1000);
    return () => clearTimeout(timeout.current);
  }, [animating]);

  useEffect(() => {
    timeout.current = null;
    setAnimating(true);
  }, [selected]);

  const createTask = useCallback(() => {
    const keys = [];
    Object.keys(servicesData).forEach((selectedKey) => {
      if ((selectedKey, checkTab(servicesData[selectedKey], selected).quantity > 0)) {
        keys.push(selectedKey);
      }
    });
    const type = (() => {
      if (keys.length === 0) return;
      if (keys.length > 1) return 161;
      switch (keys[0].toLowerCase()) {
        case 'юридические услуги':
          return 121;
        case 'восстановление учета':
          return 122;
        case 'налоговый и бухучет':
          return 120;
        case 'зик':
          return 160;
        default:
          return 161;
      }
    })();
    if (!type) return;
    copyToBuffer(text);

    window.open(`https://arm.gba.guru/internal-tasks?create=true&task_type=${type}`, '_blank');
  }, [selected, text]);

  return (
    <Box className="App" sx={{ px: 8, py: 4, height: '100dvh' }}>
      <Stack maxWidth="1312px" mx="auto">
        <Stack flexDirection="row" justifyContent="space-between" mb={'36px'} flexWrap="wrap">
          <Logo />
          <PhrasesBox phrases={phrases} />
        </Stack>
        <BackBtn />
        <Typography
          mb={3}
          variant="h1"
          sx={{ fontSize: '32px', lineHeight: '36px', fontWeight: 700 }}
        >
          Дополнительные услуги
        </Typography>

        <Stack flexDirection="row" gap={2} alignItems="start">
          <Box flex={1}>
            <Stack flexDirection="row" gap={2}>
              <TextField
                fullWidth
                placeholder="Поиск"
                sx={{ mb: 2 }}
                value={filter}
                onChange={(e) => setFiler(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search sx={{ color: '#0278FF' }} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                select
                sx={{ width: '339px', svg: { color: '#0278FF' } }}
                value={selectedTariff}
                onChange={(e) => setSelectedTariff(e.target.value)}
                SelectProps={{ IconComponent: KeyboardArrowDown }}
                label="Тариф"
              >
                {tarifs.map((tariff) => (
                  <MenuItem key={tariff.id} value={tariff.id} children={tariff.name} />
                ))}
              </TextField>
            </Stack>

            <Tabs
              sx={{
                '.MuiTabs-flexContainer': { gap: 1 },
                '.MuiTabs-indicator': { display: 'none' },
                opacity: Number(!hasFilter),
              }}
              value={tab}
              onChange={(_, v) => {
                setTab(v);
              }}
            >
              {arr.map((key) => (
                <Tab
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '46px',
                    color: '#515568',
                    '&.Mui-selected': { color: 'white', background: '#515568' },
                  }}
                  label={
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="nowrap"
                      alignItems="center"
                      justifyContent="center"
                      gap={0.5}
                      textTransform="capitalize"
                      sx={{ paddingX: '18px' }}
                    >
                      {key}
                      {checkTab(servicesData[key], selected).quantity > 0 && (
                        <Box
                          sx={{
                            borderRadius: '50%',
                            backgroundColor: '#DA0E17',
                            padding: 0.5,
                            fontSize: '10px',
                            color: 'white',
                            height: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: '15px',
                          }}
                        >
                          {checkTab(servicesData[key], selected).quantity}
                        </Box>
                      )}
                    </Box>
                  }
                  key={key}
                  value={key}
                />
              ))}
            </Tabs>
            <Stack
              flexDirection="row"
              sx={{ fontSize: '11px', fontWeight: 300 }}
              alignItems="center"
              mt={2}
              className={animating ? 'pulsating-text' : ''}
            >
              <WarningAmberOutlined sx={{ color: '#DA0E17' }} />
              Внимание, количество бесплатных услуг в рамках тарифа можнно посмотреть в&nbsp;
              <Box
                href="https://gba.business.ru/offer/?utm_medium=test#pril2-1"
                target="_blank"
                component="a"
                sx={{ color: '#DA0E17', textDecoration: 'underline' }}
              >
                договоре оферте.
              </Box>
            </Stack>
            <Box
              display="flex"
              sx={{
                marginTop: 2,
                border: '1px dashed  #DDDEE7',
                p: 2,
                display: 'flex',
                gap: 2,
                maxHeight: 'calc(100vh - 328px)',
                overflowY: 'auto',
              }}
              gap={2}
              flexDirection="column"
            >
              {list.map((item, i) => {
                if ('divider' in item) {
                  return (
                    <Box
                      sx={{
                        fontSize: '20px',
                        lineHeight: '36px',
                        fontWeight: 700,
                        minHeight: '36px',
                        display: 'flex',
                        flexWrap: 'nowrap',
                        gap: 2,
                      }}
                      key={item.divider + 'divider' + i}
                    >
                      {item.divider}
                      {item.suggestion && <Pop suggestion={item.suggestion} />}
                    </Box>
                  );
                }
                if (item.id === 'vat-refund-documents') {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        display: item.full.toUpperCase().includes(filter.toUpperCase())
                          ? 'flex'
                          : 'none',
                      }}
                      gap={2}
                    >
                      <Box width="320px">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selected[item.id].price > 0}
                                onChange={(_, val) => {
                                  setSelected((prev) => {
                                    const newV = {
                                      ...prev,
                                      [item.id]: {
                                        ...prev[item.id],
                                        quantity: +val,
                                        price: +val,
                                      },
                                    };
                                    return newV;
                                  });
                                }}
                                value={item.id}
                              />
                            }
                            sx={{ '.MuiFormControlLabel-label': { width: '100%' } }}
                            label={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ width: '100%' }}
                              >
                                {item.name}
                                {item.suggestion && <Pop suggestion={item.suggestion} />}
                              </Box>
                            }
                          ></FormControlLabel>
                        </FormGroup>
                      </Box>
                      <Box flex={1} sx={{ minWidth: '115px' }}>
                        <TextField
                          disabled={selected[item.id].quantity === 0}
                          label="Сто-сть тарифа"
                          value={selected[item.id].price}
                          FormHelperTextProps={{ sx: { mx: 0, whiteSpace: 'nowrap' } }}
                          type="number"
                          helperText={
                            <Link
                              href="/sales"
                              target="_blank"
                              sx={{
                                color: '#DA0E17',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                            >
                              Узнать сто-ть тарифа
                            </Link>
                          }
                          onChange={(e) =>
                            setSelected((prev) => ({
                              ...prev,
                              [item.id]: { ...prev[item.id], price: e.target.value },
                            }))
                          }
                        />
                      </Box>
                      <TextField
                        sx={{ width: '131px' }}
                        label="Стоимость"
                        type="number"
                        disabled={selected[item.id].quantity === 0}
                        value={selected[item.id].quantity}
                        onChange={(e) =>
                          setSelected((prev) => {
                            return {
                              ...prev,
                              [item.id]: {
                                ...prev[item.id],
                                quantity: e.target.value,
                              },
                            };
                          })
                        }
                        onBlur={(e) =>
                          setSelected((prev) => {
                            return {
                              ...prev,
                              [item.id]: {
                                ...prev[item.id],
                                quantity: Number(e.target.value),
                              },
                            };
                          })
                        }
                      />
                      <Box flex={1} sx={{ minWidth: '127px' }}>
                        <TextField
                          disabled={selected[item.id].quantity === 0}
                          select
                          label="Коэф-т сложности"
                          defaultValue={selected[item.id].coef}
                          value={selected[item.id].coef}
                          sx={{ width: '100%', svg: { color: '#0278FF' } }}
                          inputProps={{ style: { width: '100%' } }}
                          SelectProps={{ IconComponent: KeyboardArrowDown }}
                          onChange={(e) => {
                            setSelected((prev) => {
                              return {
                                ...prev,
                                [item.id]: { ...prev[item.id], coef: e.target.value },
                              };
                            });
                          }}
                        >
                          {Array(item.maxCoef || 7)
                            .fill(null)
                            .map((_, i) => (
                              <MenuItem key={'select' + i + item.id} value={i + 1}>
                                {i + 1}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Box>
                      <Box flex={1}>
                        <TextField
                          label="Скидка %"
                          value={selected[item.id].discount}
                          type="number"
                          disabled={selected[item.id].quantity === 0}
                          fullWidth
                          onChange={(e) =>
                            setSelected((prev) => {
                              return {
                                ...prev,
                                [item.id]: {
                                  ...prev[item.id],
                                  discount:
                                    +e.target.value > 100
                                      ? 100
                                      : +e.target.value < 0
                                      ? 0
                                      : e.target.value,
                                },
                              };
                            })
                          }
                        />
                      </Box>
                    </Box>
                  );
                }
                if (item.id === 'debt-collection') {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        display: item.name.toUpperCase().includes(filter.toUpperCase())
                          ? 'flex'
                          : 'none',
                      }}
                      gap={2}
                    >
                      <Box width="320px">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selected[item.id].quantity > 0}
                                onChange={(_, val) => {
                                  setSelected((prev) => ({
                                    ...prev,
                                    [item.id]: { ...prev[item.id], quantity: Number(val) },
                                  }));
                                }}
                                value={item.id}
                              />
                            }
                            sx={{ '.MuiFormControlLabel-label': { width: '100%' } }}
                            label={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ width: '100%' }}
                              >
                                {item.name}
                                {item.suggestion && <Pop suggestion={item.suggestion} />}
                              </Box>
                            }
                          ></FormControlLabel>
                        </FormGroup>
                      </Box>

                      <Box flex={1}>
                        <TextField
                          fullWidth
                          value={selected[item.id].quantity}
                          disabled={selected[item.id].quantity === 0}
                          type="number"
                          label="Собранная сумма"
                          onChange={(e) => {
                            setSelected((prev) => ({
                              ...prev,
                              [item.id]: { ...prev[item.id], quantity: e.target.value },
                            }));
                          }}
                        />
                      </Box>
                      <Box flex={1} sx={{ minWidth: '127px' }}>
                        <TextField
                          disabled={selected[item.id].quantity === 0}
                          select
                          label="Коэф-т сложности"
                          defaultValue={selected[item.id].coef}
                          value={selected[item.id].coef}
                          sx={{ width: '100%', svg: { color: '#0278FF' } }}
                          inputProps={{ style: { width: '100%' } }}
                          SelectProps={{ IconComponent: KeyboardArrowDown }}
                          onChange={(e) => {
                            setSelected((prev) => {
                              return {
                                ...prev,
                                [item.id]: { ...prev[item.id], coef: e.target.value },
                              };
                            });
                          }}
                        >
                          {Array(item.maxCoef || 7)
                            .fill(null)
                            .map((_, i) => (
                              <MenuItem key={'select' + i + item.id} value={i + 1}>
                                {i + 1}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Box>
                      <Box flex={1}>
                        <TextField
                          label="Скидка %"
                          value={selected[item.id].discount}
                          type="number"
                          disabled={selected[item.id].quantity === 0}
                          fullWidth
                          onChange={(e) =>
                            setSelected((prev) => {
                              return {
                                ...prev,
                                [item.id]: {
                                  ...prev[item.id],
                                  discount:
                                    +e.target.value > 100
                                      ? 100
                                      : +e.target.value < 0
                                      ? 0
                                      : e.target.value,
                                },
                              };
                            })
                          }
                        />
                      </Box>
                      <Box flex={1}>
                        <TextField
                          label="Стоимость"
                          disabled={selected[item.id].quantity === 0}
                          fullWidth
                          value={calculateItem(selected[item.id], selectedTariff).toFixed(2)}
                        />
                      </Box>
                    </Box>
                  );
                }
                if (item.id === 'managerial-accounting') {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        display: item.name.toUpperCase().includes(filter.toUpperCase())
                          ? 'flex'
                          : 'none',
                      }}
                      gap={2}
                    >
                      <Box width="320px">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selected[item.id].quantity > 0}
                                onChange={(_, val) => {
                                  setSelected((prev) => ({
                                    ...prev,
                                    [item.id]: { ...prev[item.id], quantity: Number(val) },
                                  }));
                                }}
                                value={item.id}
                              />
                            }
                            sx={{ '.MuiFormControlLabel-label': { width: '100%' } }}
                            label={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ width: '100%' }}
                              >
                                {item.name}
                                {item.suggestion && <Pop suggestion={item.suggestion} />}
                              </Box>
                            }
                          ></FormControlLabel>
                        </FormGroup>
                      </Box>
                      <Box flex={1} sx={{ minWidth: '115px' }}>
                        <TextField
                          label="Базовая стоимость"
                          type="number"
                          disabled={selected[item.id].quantity === 0}
                          value={selected[item.id].price}
                          onChange={(e) =>
                            setSelected((prev) => ({
                              ...prev,
                              [item.id]: {
                                ...prev[item.id],
                                price: e.target.value.replace(/\0-9/g, ''),
                              },
                            }))
                          }
                        />
                      </Box>

                      <Box flex={1}>
                        <TextField
                          fullWidth
                          value={selected[item.id].quantity}
                          disabled={selected[item.id].quantity === 0}
                          type="number"
                          label="Кол-во"
                          onChange={(e) => {
                            setSelected((prev) => ({
                              ...prev,
                              [item.id]: {
                                ...prev[item.id],
                                quantity:
                                  +e.target.value > 100
                                    ? 100
                                    : +e.target.value < 0
                                    ? 0
                                    : e.target.value,
                              },
                            }));
                          }}
                        />
                      </Box>
                      <TextField
                        select
                        label="Коэф-т сложности"
                        disabled={selected[item.id].quantity === 0}
                        defaultValue={selected[item.id].coef}
                        value={selected[item.id].coef}
                        sx={{ width: '100%', svg: { color: '#0278FF' }, flex: 1 }}
                        inputProps={{ style: { width: '100%' } }}
                        SelectProps={{ IconComponent: KeyboardArrowDown }}
                        onChange={(e) => {
                          setSelected((prev) => {
                            return {
                              ...prev,
                              [item.id]: { ...prev[item.id], coef: e.target.value },
                            };
                          });
                        }}
                      >
                        {Array(item.maxCoef || 7)
                          .fill(null)
                          .map((_, i) => (
                            <MenuItem key={'select' + i + item.id} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                      </TextField>
                      <Box flex={1}>
                        <TextField
                          disabled={selected[item.id].quantity === 0}
                          label="Скидка %"
                          value={selected[item.id].discount}
                          type="number"
                          fullWidth
                          onChange={(e) =>
                            setSelected((prev) => {
                              return {
                                ...prev,
                                [item.id]: {
                                  ...prev[item.id],
                                  discount:
                                    +e.target.value > 100
                                      ? 100
                                      : +e.target.value < 0
                                      ? 0
                                      : e.target.value,
                                },
                              };
                            })
                          }
                        />
                      </Box>
                      <Box flex={1}>
                        <TextField
                          disabled={selected[item.id].quantity === 0}
                          label="Стоимость"
                          fullWidth
                          value={calculateItem(selected[item.id], selectedTariff).toFixed(2)}
                        />
                      </Box>
                    </Box>
                  );
                }
                return (
                  <Box
                    key={item.id}
                    sx={{
                      display: item.name.toUpperCase().includes(filter.toUpperCase())
                        ? 'flex'
                        : 'none',
                    }}
                    gap={2}
                  >
                    <Box width="320px">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selected[item.id].quantity > 0}
                              onChange={(_, val) => {
                                setSelected((prev) => ({
                                  ...prev,
                                  [item.id]: { ...prev[item.id], quantity: Number(val) },
                                }));
                              }}
                              value={item.id}
                            />
                          }
                          sx={{ '.MuiFormControlLabel-label': { width: '100%' } }}
                          label={
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{ width: '100%' }}
                            >
                              {item.name}
                              {item.suggestion && <Pop suggestion={item.suggestion} />}
                            </Box>
                          }
                        ></FormControlLabel>
                      </FormGroup>
                    </Box>
                    {'tariff' in item && (
                      <Box flex={1} sx={{ minWidth: '115px' }}>
                        <TextField
                          disabled={selected[item.id].quantity === 0}
                          label="Сто-сть тарифа"
                          value={selected[item.id].price}
                          FormHelperTextProps={{ sx: { mx: 0, whiteSpace: 'nowrap' } }}
                          type="number"
                          helperText={
                            <Link
                              href="/sales"
                              target="_blank"
                              sx={{
                                color: '#DA0E17',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                            >
                              Узнать сто-ть тарифа
                            </Link>
                          }
                          onChange={(e) =>
                            setSelected((prev) => ({
                              ...prev,
                              [item.id]: { ...prev[item.id], price: e.target.value },
                            }))
                          }
                        />
                      </Box>
                    )}
                    <Box flex={1}>
                      <TextField
                        fullWidth
                        disabled={selected[item.id].quantity === 0}
                        value={selected[item.id].quantity}
                        type="number"
                        label="Кол-во"
                        onChange={(e) => {
                          setSelected((prev) => ({
                            ...prev,
                            [item.id]: {
                              ...prev[item.id],
                              quantity:
                                +e.target.value > 100
                                  ? 100
                                  : +e.target.value < 0
                                  ? 0
                                  : e.target.value,
                            },
                          }));
                        }}
                      />
                    </Box>
                    {item.id !== 'legal-zero-reports-past-periods' && (
                      <>
                        <Box flex={1} sx={{ minWidth: '127px' }}>
                          <TextField
                            select
                            disabled={selected[item.id].quantity === 0}
                            label="Коэф-т сложности"
                            defaultValue={selected[item.id].coef}
                            value={selected[item.id].coef}
                            sx={{ width: '100%', svg: { color: '#0278FF' } }}
                            inputProps={{ style: { width: '100%' } }}
                            SelectProps={{ IconComponent: KeyboardArrowDown }}
                            onChange={(e) => {
                              setSelected((prev) => {
                                return {
                                  ...prev,
                                  [item.id]: { ...prev[item.id], coef: e.target.value },
                                };
                              });
                            }}
                          >
                            {Array(item.maxCoef || 7)
                              .fill(null)
                              .map((_, i) => (
                                <MenuItem key={'select' + i + item.id} value={i + 1}>
                                  {i + 1}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Box>
                        <Box flex={1}>
                          <TextField
                            disabled={selected[item.id].quantity === 0}
                            label="Скидка %"
                            value={selected[item.id].discount}
                            type="number"
                            fullWidth
                            onChange={(e) =>
                              setSelected((prev) => {
                                return {
                                  ...prev,
                                  [item.id]: {
                                    ...prev[item.id],
                                    discount:
                                      +e.target.value > 100
                                        ? 100
                                        : +e.target.value < 0
                                        ? 0
                                        : e.target.value,
                                  },
                                };
                              })
                            }
                          />
                        </Box>
                      </>
                    )}
                    <Box flex={1}>
                      <TextField
                        label="Стоимость"
                        disabled={selected[item.id].quantity === 0}
                        fullWidth
                        value={calculateItem(selected[item.id], selectedTariff).toFixed(2)}
                        onChange={() => null}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Stack
            flexDirection="column"
            width="340px"
            sx={{
              gap: '26px',
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 169px)',
              mt: -0.5,
              pt: 0.5,
            }}
          >
            <CalcSelect />
            <TextField
              value={res.toFixed(2)}
              label="Общая стоимость"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CurrencyRuble sx={{ color: '#9FA4BB' }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              select
              value={totalDifficult}
              sx={{ width: '100%', svg: { color: '#0278FF' } }}
              SelectProps={{ IconComponent: KeyboardArrowDown }}
              onChange={(e) => {
                setTotalDifficult(e.target.value);
              }}
              label="Общий коэф-т сложности"
            >
              {Array(7)
                .fill(null)
                .map((_, i) => (
                  <MenuItem key={'select-dif' + i} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              label="Общая скидка"
              value={totalDiscount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Percent sx={{ color: '#9FA4BB' }} />
                  </InputAdornment>
                ),
              }}
              type="number"
              onChange={(e) => {
                setTotalDiscount(
                  +e.target.value > 100
                    ? 100
                    : +e.target.value < 0
                    ? 0
                    : e.target.value.replace(/\0-9/g, ''),
                );
              }}
            />
            <TextField
              label={<Box sx={{ backgroundColor: '#F2F3F8', px: 0.5 }}>Описание для клиента:</Box>}
              multiline
              sx={{ flex: 1, display: 'flex' }}
              value={text}
              InputProps={{
                sx: { flex: 1, alignItems: 'start', fontSize: '10pt' },
              }}
            />
            <Stack gap="10px">
              <Button
                startIcon={<CopyAllOutlined />}
                sx={{ textTransform: 'none' }}
                variant="outlined"
                onClick={() => copyToBuffer(text)}
              >
                Копировать
              </Button>
              <Button
                startIcon={<Add />}
                sx={{ textTransform: 'none' }}
                variant="outlined"
                onClick={createTask}
                endIcon={
                  <Pop
                    width={'370px'}
                    suggestion={`Кнопка создания внутренней задачи в ГБА АРМ. Тип задачи установится в соответствии с выбранными пунктами во вкладках калькулятора:<br />
Тип "603. Продажа. Бухгалтерская услуга" - вкладка "Налоговый и бухучет"<br />
Тип "604. Продажа. Юридическая услуга" - вкладка "Юридические услуги"<br />
Тип "605. Продажа. Восстановление\\перенос базы" - вкладка "Восстановление учета"<br />
Тип "607. Продажа. ЗиК" - вкладка "ЗиК"<br />
Тип "602. Продажа. Сборная услуга" - если выбраны услуги из разных вкладок`}
                  />
                }
              >
                Создать задачу
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Dop;
