module.exports = {
  'юридические услуги': [
    {
      id: 'legal-consultation-oral',
      name: 'Устная юридическая консультация',
      cost: 2000,
      full: 'Устная юридическая консультация',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 0,
        premium: 0,
      },
      free: [['expert', 5]]
    },
    {
      id: 'legal-consultation-written',
      name: 'Письменная юридическая консультация',
      cost: 2000,
      full: 'Письменная юридическая консультация',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 0,
        premium: 0,
      },
      free: [['expert', 10]]
    },
    {
      id: 'legal-template-selection',
      name: 'Подбор формы договора, документа',
      cost: 1000,
      full: 'Подбор шаблонной формы договора или документа',
      costs: {
        standart: 1500,
        expert: 0,
        business: 0,
        premium: 0,
      },
      free: [['standard', 10]]
    },
    {
      id: 'legal-contract-preparation',
      name: 'Подготовка договора',
      cost: 3000,
      full: 'Подготовка договора на базе стандартного имеющегося с внесением корректировок в основные условия), составление дополнительных соглашений к заключенным договорам и соглашений о расторжении заключенных договоров',
      suggestion:
        'На базе стандартного имеющегося с внесением корректировок в основные условия, а также составление дополнительных соглашений к заключенным договорам и соглашений о расторжении заключенных договоров',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 0,
        premium: 0,
      },
    },
    {
      divider: 'Анализ норм договора',
      ids: ['legal-verbal-conclusion', 'legal-written-conclusion'],
    },
    {
      id: 'legal-verbal-conclusion',
      name: 'С устным заключением',
      cost: 3000,
      full: 'Анализ норм договора с устным заключением',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-written-conclusion',
      name: 'С письменным заключением',
      cost: 3000,
      full: 'Анализ норм договора с письменным заключением',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 0,
        premium: 0,
      },
      free: [['expert', 5]]
    },
    {
      id: 'legal-disagreement-protocol',
      name: 'Протокол разногласий к договору',
      cost: 3000,
      full: 'Составление Протокола разногласий к договору',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 0,
        premium: 0,
      },
    },
    {
      divider: '',
    },
    {
      id: 'legal-registration-branches',
      name: 'Гос-ная рег-я филиалов, представительств',
      cost: 10000,
      full: 'Подготовка документов для государственной регистрации филиалов, представительств (без подачи документов в налоговую)',
      suggestion: 'Подготовка документов без подачи документов в налоговую',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 0,
      },
    },
    {
      id: 'legal-single-participant-resolution',
      name: 'Решения единственного участника ООО',
      cost: 3000,
      full: 'Оформление Решений единственного участника ООО (не связанной с регистрацией ООО)',
      suggestion: 'Оформление не связанной с регистрацией ООО',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-assembly-protocols',
      name: 'Протоколы собраний участников ООО',
      cost: 3000,
      full: 'Оформление Протоколов общего собрания участников ООО (не связанной с регистрацией ООО)',
      suggestion: 'Оформление не связанной с регистрацией ООО',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-okved-assistance',
      name: 'ОКВЭД',
      cost: 3000,
      full: 'Помощь в подборе ОКВЭД - до 5 штук (без подачи документов в налоговую)',
      suggestion: 'Помощь в подборе без подачи документов в налоговую',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-egryul-changes',
      name: 'Документы для изменений в ЕГРЮЛ',
      cost: 5000,
      full: 'Подготовка документов для внесения изменений в сведения в ЕГРЮЛ о юридических лицах (смена директора, ОКВЭД, телефон, ошибки, без подачи документов в налоговую)',
      suggestion: 'Смена: директора, ОКВЭД, телефон, ошибки; без подачи документов в налоговую',
      costs: {
        standart: 7500,
        expert: 7500,
        business: 7500,
        premium: 0,
      },
      free: [['business', 3]]
    },
    {
      id: 'legal-documents-change',
      name: 'Изменение учредительных документов',
      cost: 5000,
      full: 'Подготовка документов по внесение изменений в учредительные документы смена адреса в 1 этап (без подачи документов в налоговую)',
      suggestion: 'смена адреса в 1 этап',
      costs: {
        standart: 7500,
        expert: 7500,
        business: 7500,
        premium: 0,
      },
      free: [
        ['business', 5],
        ['expert', 1],
      ]
    },
    {
      id: 'legal-address-change-stage-2',
      name: 'Смена адреса в 2 этапа',
      cost: 10000,
      full: 'Смена адреса в 2 этапа',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 0,
      },
      free: [
        ['business', 1],
      ]
    },
    {
      id: 'legal-capital-increase',
      name: 'Увеличение уставного капитала',
      cost: 10000,
      full: 'Увеличение уставного капитала',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 0,
      },
      free: [
        ['business', 1],
      ]
    },
    {
      id: 'legal-documents-check',
      name: 'Юр. проверка учредительных документов',
      cost: 5000,
      full: 'Юридическая проверка готовых учредительных документов клиента',
      costs: {
        standart: 10000,
        expert: 10000,
        business: 10000,
        premium: 0,
      },
      free: [
        ['business', 1],
      ]
    },
    {
      id: 'legal-organization-liquidation',
      name: 'Ликвидация организации',
      cost: 15000,
      suggestion:
        'Без процедуры банкротства, без подготовки ликвидационных балансов и без публикаций в Вестнике государственной регистрации',
      full: 'Ликвидация организации без процедуры банкротства, без подготовки ликвидационных балансов и публикаций в Вестнике государственной регистрации',
      costs: {
        standart: 20000,
        expert: 20000,
        business: 20000,
        premium: 0,
      },
    },
    {
      id: 'legal-egrip-changes',
      name: 'Документы для изменений в ЕГРИП',
      cost: 3000,
      suggestion:
        'Изменения сведений об ИП: паспортных данных, ОКВЭД; без подачи документов в налоговую',
      full: 'Подготовка документов по внесению изменений в сведения об индивидуальном предпринимателе в ЕГРИП (изменение паспортных данных, ОКВЭД) (без подачи документов в налоговую)',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 4500,
        premium: 0,
      },
      free: [
        ['business', 1],
      ]
    },
    {
      id: 'legal-business-liquidation',
      name: 'Ликвидация предпринимательской деятельности',
      cost: 5000,
      suggestion: 'Без решения вопросов с СФР',
      full: 'Ликвидация предпринимательской деятельности без решения вопросов с СФР',
      costs: {
        standart: 7000,
        expert: 7000,
        business: 7000,
        premium: 0,
      },
      free: [
        ['business', 1],
      ]
    },
    {
      id: 'legal-case-analysis',
      name: 'Анализ судебного дела',
      cost: 10000,
      suggestion:
        'Изучение представленных материалов до 20 листов в общем объеме представленных документов',
      full: 'Анализ судебного дела (изучение представленных материалов до 20 листов в общем объеме представленных документов) ',
      costs: {
        standart: 20000,
        expert: 20000,
        business: 20000,
        premium: 0,
      },
      free: [
        ['business', 1]
      ]
    },
    {
      id: 'legal-documents',
      name: 'Правовые документы',
      cost: 5000,
      suggestion: 'Заявления, жалобы, претензии, ходатайства, без участия в судебном процессе',
      full: 'Составление заявлений, жалоб, претензий, ходатайств (без участия в судебном процессе)',
      costs: {
        standart: 10000,
        expert: 10000,
        business: 7500,
        premium: 0,
      },
      free: [
        ['business', 3]
      ]
    },
    {
      id: 'legal-power-of-attorney',
      name: 'Составление доверенностей',
      cost: 1000,
      suggestion: 'Для почты, налоговой, судов и др.',
      full: 'Составление доверенностей (для почты, налоговой, суды и др)',
      costs: {
        standart: 2000,
        expert: 2000,
        business: 0,
        premium: 0,
      },
      free: [
        ['expert', 5]
      ]
    },
    {
      id: 'legal-order-open-branch',
      name: 'Приказ по открытию ОП',
      cost: 3000,
      full: 'Подготовка Приказа по открытию обособленного подразделения',
      costs: {
        standart: 4000,
        expert: 4000,
        business: 4000,
        premium: 0,
      },
      free: [
        ['business', 1]
      ]
    },
    {
      id: 'legal-order-close-branch',
      name: 'Приказ по закрытию ОП',
      cost: 3000,
      full: 'Подготовка Приказа по закрытию обособленного подразделения',
      costs: {
        standart: 4000,
        expert: 4000,
        business: 4000,
        premium: 0,
      },
      free: [
        ['business', 1]
      ]
    },
    {
      name: 'Юр. анализ приказов',
      id: 'ur-analiz-prikazov',
      cost: 5000,
      full: 'Юр. анализ приказов',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      },
      free: [
        ['business', 1]
      ]
    },
    {
      id: 'legal-analysis-local-acts',
      name: 'Юр. анализ локальных норм. актов',
      cost: 5000,
      full: 'Юридический анализ локальных нормативных актов (инструкций, положений)',
      suggestion: 'инструкций, положений',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 5000,
        premium: 0,
      },
      free: [
        ['business', 1]
      ]
    },
    {
      id: 'legal-individual-documents',
      name: 'Индивидуальные документы',
      cost: 1000,
      full: 'Разработка иных индивидуальных документов по деятельности организации',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      },
      free: [
        ['business', 4],
        ['expert', 3]
      ]
    },
  ],
  'восстановление учета': [
    {
      id: 'legal-zero-reports-past-periods',
      name: 'Нулевые отчеты за прошлые периоды',
      cost: 1000,
      suggestion: 'Стоимость указана за один отчет',
      full: 'Нулевые отчеты за прошлые периоды\r\n*стоимость указана за один отчет',
      costs: {
        standart: 2000,
        expert: 2000,
        business: 2000,
        premium: 2000,
      },
    },
    {
      id: '1c-data-transfer-from-away',
      name: 'Перенос данных из иных учетных систем в 1С:Бухгалтерия Предприятия 8.3 без восстановления учета',
      full: 'Перенос данных из иных учетных систем в 1С:Бухгалтерия Предприятия 8.3 без восстановления учета',
      tariffs: {
        standart: 70,
        expert: 70,
        business: 70,
        premium: 70,
      },
      tariff: 70,

    },
    {
      name: "Восстановление учета одного месяца со сдачей квартальной и годовой отчетности (март, июнь, сентябрь, декабрь)",
      id: 'legal-recovery-one-month-quarter-year-reports',
      full: 'Восстановление учета одного месяца со сдачей квартальной и годовой отчетности (март, июнь, сентябрь, декабрь)',
      tariffs: {
        standart: 100,
        expert: 100,
        business: 100,
        premium: 100,
      },
      tariff: 100,
    },
    {
      name: "Восстановление входящих остатков на первое число месяца начала даты обслуживания",
      id: 'legal-recovery-initial-balance-first-day-of-month',
      full: 'Восстановление входящих остатков на первое число месяца начала даты обслуживания',
      tariffs: {
        standart: 100,
        expert: 100,
        business: 100,
        premium: 100,
      },
      tariff: 100,
    },
    {
      id: 'legal-tax-accounting-reporting',
      name: 'Подготовка налоговой и бухгалтерской отчетности',
      cost: 3000,
      suggestion:
        'На основании имеющихся данных Заказчика без анализа и восстановления; стоимость указана за один отчет',
      full: 'Подготовка налоговой и бухгалтерской отчетности на основании имеющихся данных Заказчика без анализа и восстановления\r\n*стоимость указана за один отчет',
      costs: {
        standart: 6000,
        expert: 6000,
        business: 6000,
        premium: 6000,
      },
    },
    {
      id: 'legal-labor-relations',
      name: 'Оформление трудовых отношений на предприятии',
      cost: 3000,
      suggestion:
        '(Подготовка полного пакета внутренних документов на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя)',
      full: 'Оформление трудовых отношений на предприятии (подготовка полного пакета внутренних документов, необходимых для осуществления деятельности предприятия) на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 3000,
      },
    },
    {
      id: 'legal-income-expense-book',
      name: 'Книга доходов и расходов, декларации по УСН-Д',
      cost: 10000,
      suggestion: 'На основании банковской выписки без восстановления учета',
      full: 'Формирование Книги доходов и расходов, декларации по УСН (Доходы) на основании банковской выписки без восстановления учета',
    },
  ],
  'налоговый и бухучет': [
    {
      divider: 'Экспресс-аудит базы',
      suggestion: '1С: Бухгалтерия Предприятия 7.7, 8.2, 8.3',
    },
    {
      id: 'legal-express-audit-1c-usnd',
      name: 'Экспресс-аудит базы (УСН (Д))',
      cost: 2000,
      full: 'Экспресс-аудит базы 1С: Бухгалтерия Предприятия 7.7, 8.2, 8.3 (УСН (Д))',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      },
    },
    {
      id: 'legal-express-audit-1c-usndr',
      name: 'Экспресс-аудит базы (УСН (Д-Р))',
      cost: 2000,
      full: 'Экспресс-аудит базы 1С: Бухгалтерия Предприятия 7.7, 8.2, 8.3 (УСН (Д-Р))',
      costs: {
        standart: 6000,
        expert: 6000,
        business: 6000,
        premium: 0,
      },
    },
    {
      id: 'legal-express-audit-1c-osno',
      name: 'Экспресс-аудит базы (ОСНО)',
      cost: 2000,
      full: 'Экспресс-аудит базы 1С: Бухгалтерия Предприятия 7.7, 8.2, 8.3 (ОСНО))',
      costs: {
        standart: 6000,
        expert: 6000,
        business: 6000,
        premium: 0,
      },
    },
    {
      id: 'legal-primary-documents-input',
      name: 'Обработка и ввод первичных документов',
      cost: 2000,
      suggestion:
        'После 10 числа месяца за предыдущий месяц обслуживания, следующего за отчетным периодом',
      full: 'Обработка и ввод первичных документов после 10 числа месяца за предыдущий месяц обслуживания, следующего за отчетным периодом',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      },
    },
    {
      name: 'Обработка банковской выписки за предыдущий месяц обслуживания, поступившей после 3 числа следующего месяца',
      id: 'legal-bank-statements-input',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      }
    },
    {
      divider: 'Корректировка или изменение сданной отчетности',
      suggestion: 'При изменении Заказчиком данных в документах или в учете',
    },
    {
      id: 'legal-vat-profit-quarter-2',
      name: 'за квартал по НДС и Прибыли',
      cost: 3000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (за квартал по НДС и Прибыли) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 6000,
        expert: 6000,
        business: 6000,
        premium: 0,
      },
    },
    {
      id: 'legal-simplified-tax-system-2-d',
      name: 'УСН Д за налоговый период',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (УСН Д за налоговый период) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 8000,
        expert: 8000,
        business: 8000,
        premium: 0,
      },
    },
    {
      id: 'legal-simplified-tax-system-2-d-r',
      name: 'УСН Д-Р за налоговый период',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (УСН Д-Р за налоговый период) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 0,
      },
    },
    {
      id: 'legal-reports-assets-water-tax-1',
      name: 'в отчетах Имущество, водный налог',
      cost: 3000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (в отчетах Имущество, водный налог) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      },
    },
    {
      id: 'legal-accounting-reports-1',
      name: 'бухгалтерской отчетности (упрощенная форма)',
      cost: 3500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (бухгалтерской отчетности (упрощенная форма)) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 5000,
        premium: 0,
      },
    },
    {
      name: 'Полная бухгалтерская отчетность с приложениями',
      id: 'legal-accounting-reports-full-1',
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (Полная бухгалтерская отчетность с приложениями) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 10000,
        expert: 10000,
        business: 10000,
        premium: 0,
      }
    },
    {
      name: 'Подоготовка промежуточной бухгалтерской отчетности',
      id: 'legal-intermediate-accounting-report-1',
      full: 'Подоготовка промежуточной бухгалтерской отчетности',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 5000,
        premium: 0,
      }
    },
    {
      divider: 'Корректировка сданной отчетности за период без ГБА',
      suggestion: 'Когда Заказчик не сопровождался в Главбух',
    },
    {
      id: 'legal-vat-profit-quarter-alt',
      name: 'за квартал по НДС и Прибыли',
      cost: 4000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (за квартал по НДС и Прибыли) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 8500,
        expert: 8500,
        business: 8500,
        premium: 0,
      },
    },
    {
      id: 'legal-simplified-tax-system-alt-d',
      name: 'УСН Д за налоговый период',
      cost: 5500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (УСН Д за налоговый период) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 11000,
        expert: 11000,
        business: 11000,
        premium: 0,
      },
    },
    {
      id: 'legal-simplified-tax-system-alt-d-r',
      name: 'УСН Д-Р за налоговый период',
      cost: 5500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (УСН Д-Р за налоговый период) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 21000,
        expert: 21000,
        business: 21000,
        premium: 0,
      },
    },
    {
      id: 'legal-reports-assets-water-tax-alt',
      name: 'в отчетах Имущество, Водный налог',
      cost: 3500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (в отчетах Имущество, Водный налог) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 4000,
        expert: 4000,
        business: 4000,
        premium: 0,
      },
    },
    {
      id: 'legal-accounting-reports-alt',
      name: 'бухгалтерской отчетности (упрощенная форма)',
      cost: 3500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (бухгалтерской отчетности (упрощенная форма)) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 7000,
        expert: 7000,
        business: 7000,
        premium: 0,
      },
    },
    {
      name: "Полная бухгалтерская отчетность с приложениями",
      id: 'legal-accounting-reports-full-2',
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (Полная бухгалтерская отчетность с приложениями) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 14000,
        expert: 14000,
        business: 14000,
        premium: 0,
      },
    },
    {
      name: 'Корректировка уведомления по налогам в ИФНС и подготовка уведомления за период, когда Заказчик не сопровождался в Главбух Ассистент',
      id: 'korrectirovka-uved-po-nalogam',
      full: 'Корректировка уведомления по налогам в ИФНС и подготовка уведомления за период, когда Заказчик не сопровождался в Главбух Ассистент',
      costs: {
        standart: 2000,
        expert: 2000,
        business: 2000,
        premium: 0,
      },
    },
    {
      id: 'document-preparation',
      name: 'Подготовка документов, отчетов по данным и по нерегламентированной форме Заказчика',
      cost: 2500,
      full: 'Подготовка документов, отчетов по данным и по нерегламентированной форме Заказчика',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 5000,
        premium: 0,
      },
    },
    {
      id: 'additional-account-maintenance',
      name: 'Ведение дополнительного р/с',
      cost: 1000,
      full: 'Ведение дополнительного расчетного счета',
      costs: {
        standart: 1000,
        expert: 1000,
        business: 1000,
        premium: 0,
      },
    },
    {
      name: 'Загрузка данных из ОФД',
      id: 'upload-ofd',
      full: 'Загрузка данных из ОФД',
      costs: {
        standart: 1000,
        expert: 1000,
        business: 1000,
        premium: 0,
      },
    },
    {
      id: 'accounting-for-branch',
      name: 'Ведение учета по ОП',
      cost: 4500,
      full: 'Ведение учета по обособленному подразделению',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      },
    },
    {
      name: 'Подтверждение основного вида деятельности за период, когда Заказчик не сопровождался в Главбух Ассистент',
      id: 'confirmation-of-main-activity',
      full: 'Подтверждение основного вида деятельности за период, когда Заказчик не сопровождался в ��лавбух Ассистент',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      }
    },
    {
      id: 'statistical-reports',
      name: 'Отчеты в органы Статистики',
      cost: 1000,
      maxCoef: 7,
      full: 'Подготовка отчетов в органы Статистики\r\n* зависимости от сложности отчета, может быть применен повышающий коэффициент от 2 до 7',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      },
      free: [
        ['standart', 3],
        ['expert', 3],
        ['business', 3],
      ]
    },
    {
      name: 'Подготовка отчетов в органы Статистики за период, когда Заказчик не сопровождался в Главбух Ассистент',
      id:'statistical-reports-alt',
      full: 'Подготовка отчетов в органы Статистики за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от сложности отчета, может быть применен повышающий коэффициент от 2 до 7',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      },
      maxCoef: 7,
  
    },
    {
      id: 'vat-refund-documents',
      name: 'Документы на возмещение НДС',
      cost: 30000,
      suggestion: 'из бюджета',
      full: 'Подготовка документов на возмещение НДС из бюджета',
      costs: {
        standart: 45000,
        expert: 45000,
        business: 45000,
        premium: 0,
      },
    },
    {
      id: 'report-6-ndfL',
      name: 'Отчеты 6-НДФЛ по ФЛ',
      cost: 0,
      suggestion: 'По которым заказчик является налоговым агентом',
      full: 'Подготовка отчетов 6-НДФЛ по физическим лицам (далее ФЛ), по которым Заказчик является налоговым агентом',
      costs: {
        standart: 1000,
        expert: 1000,
        business: 1000,
        premium: 0,
      }
    },
    {
      id: 'responses-to-government-requests',
      name: 'Ответы на требования от гос органов',
      cost: 3000,
      maxCoef: 6,
      suggestion:
        'когда Заказчик не сопровождался в Главбух Ассистент, без сдачи корректирующей отчетности.',
      full: 'Подготовка ответов на Требования от государственных органов за период, когда Заказчик не сопровождался в Главбух Ассистент, без сдачи корректирующей отчетности\r\n* зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 5000,
        premium: 0,
      },
      free: [
        ['business', 3],
      ]
    },
    {
      id: 'tax-optimization-consultation',
      name: 'Консультации по оптимизации налогообложения',
      cost: 10000,
      full: 'Консультации по вопросам оптимизации по налогообложению',
      costs: {
        standart: 10000,
        expert: 10000,
        business: 10000,
        premium: 10000,
      },
    },
    {
      id: 'managerial-accounting',
      name: 'Управленческий учет',
      suggestion: 'консультация, помощь в настройке, контроль данных',
      cost: 0,
      full: 'Управленческий учет (консультация, помощь в настройке, контроль данных)',
    },
    {
      id: 'foreign-economic-activity',
      name: 'ВЭД',
      cost: 0,
      tariff: 25,
      tariffs: {
        standart: 25,
        expert: 25,
        business: 25,
        premium: 25,
      },
      full: 'Внешнеэкономическая деятельность',
    },
    {
      id: 'non-server-accounting',
      name: 'Ведение учета в любых программах, размещенных не на сервере Исполнителя',
      cost: 0,
      tariff: 50,
      tariffs: {
        standart: 50,
        expert: 50,
        business: 50,
        premium: 50,
      },
      full: 'Ведение учета в любых программах, размещенных не на сервере Исполнителя',
    },
    // {
    //   id: 'debt-collection',
    //   name: 'Сбор дебиторской задолженности',
    //   cost: 2.5,
    //   full: 'Сбор дебиторской задолженности',
    //   costs: {
    //     standart: 2.5,
    //     expert: 2.5,
    //     business: 2.5,
    //     premium: 0,
    //   },
    // },
    // {
    //   id: 'collection-of-primary-documents',
    //   name: 'Сбор первичных документов',
    //   cost: 2500,
    //   suggestion: 'с контрагентов',
    //   full: 'Сбор первичных документов с контрагентов',
    //   costs: {
    //     standart: 3750,
    //     expert: 0,
    //     business: 0,
    //     premium: 0,
    //   },
    // },
  ],
  зиК: [
    {
      id: 'salary-recalculation',
      name: 'Повторный расчет заработной платы, аванса, отпуска, больничного, увольнения',
      cost: 900,
      suggestion: 'В связи с внесенными изменениями по запросу Заказчика',
      full: 'Повторный расчет заработной платы, аванса, отпуска, больничного, увольнения в связи с внесенными изменениями по запросу Заказчика',
      costs: {
        standart: 1500,
        expert: 1500,
        business: 1500,
        premium: 0,
      },
    },
    {
      divider: 'Корректировка сданной отчетности',
      suggestion: 'при изменении Заказчиком данных в документах или в учете',
      id: 'report-correction',

    },
    {
      id: 'rsv',
      name: 'РСВ',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (РСВ) при изменении Заказчиком данных в документах или в учете\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 6000,
        expert: 6000,
        business: 6000,
        premium: 0,
      },
    },
    {
      id: 'efs-1',
      name: 'ЕФС-1 (в части взносов на травматизм)',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (ЕФС-1 (в части взносов на травматизм)) при изменении Заказчиком данных в документах или в учете\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 6000,
        expert: 6000,
        business: 6000,
        premium: 0,
      },
    },
    {
      id: 'pers-1',
      name: 'персонифицированные сведения',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (персонифицированные сведения) при изменении Заказчиком данных в документах или в учете\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 2500,
        expert: 2500,
        business: 2500,
        premium: 0,
      },
    },
    {
      id: '6ndfl',
      name: '6-НДФЛ',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности ( 6-НДФЛ) при изменении Заказчиком данных в документах или в учете\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 8000,
        expert: 8000,
        business: 8000,
        premium: 0,
      },
    },

    {
      id: 'efs-1-chapter-1-2',
      name: 'ефс-1 подраздел 1.2 (Сведения о страховом стаже)',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (ефс-1 подраздел 1.2 (Сведения о страховом стаже)) при изменении Заказчиком данных в документах или в учете\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 5000,
        premium: 0,
      },
    },

    {
      id: 'efs-1-chapter-1-1',
      name: 'ефс-1 подраздел 1.1 (Сведения о трудовой (иной) деятельности)',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (ефс-1 подраздел 1.1 (Сведения о трудовой (иной) деятельности)) при изменении Заказчиком данных в документах или в учете\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 2500,
        expert: 2500,
        business: 2500,
        premium: 0,
      },
    },


    {
      divider: 'Корректировка сданной отчетности за период без ГБА',
      suggestion: 'за период, когда Заказчик не сопровождался у Исполнителя',
      id: 'report-correction',

    },
    {
      id: 'rsv-1',
      name: 'РСВ',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (РСВ) когда Заказчик не сопровождался у Исполнителя\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 8500,
        expert: 8500,
        business: 8500,
        premium: 0,
      },
    },
    {
      id: 'efs-1-1',
      name: 'ЕФС-1 (в части взносов на травматизм)',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (ЕФС-1 (в части взносов на травматизм)) когда Заказчик не сопровождался у Исполнителя\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 8500,
        expert: 8500,
        business: 8500,
        premium: 0,
      },
    },
    {
      id: 'pers-1-1',
      name: 'персонифицированные сведения',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (персонифицированные сведения) когда Заказчик не сопровождался у Исполнителя\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      },
    },
    {
      id: '6ndfl-1',
      name: '6-НДФЛ',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности ( 6-НДФЛ) когда Заказчик не сопровождался у Исполнителя\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 11000,
        expert: 11000,
        business: 11000,
        premium: 0,
      },
    },

    {
      id: 'efs-1-chapter-1-2-1',
      name: 'ефс-1 подраздел 1.2 (Сведения о страховом стаже)',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (ефс-1 подраздел 1.2 (Сведения о страховом стаже)) когда Заказчик не сопровождался у Исполнителя\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 6500,
        expert: 6500,
        business: 6500,
        premium: 0,
      },
    },

    {
      id: 'efs-1-chapter-1-1-1',
      name: 'ефс-1 подраздел 1.1 (Сведения о трудовой (иной) деятельности)',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (ефс-1 подраздел 1.1 (Сведения о трудовой (иной) деятельности)) когда Заказчик не сопровождался у Исполнителя\r\n* зависимости от количества сотрудников, по которым требуется вносить изменения, может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 6500,
        expert: 6500,
        business: 6500,
        premium: 0,
      },
    },
    {
      name: 'Внесение изменений в уже согласованные кадровые мероприятия по инициативе Заказчика',
      id: 'uzhe-soglasovan-meropriyat',
      full: 'Внесение изменений в уже согласованные кадровые мероприятия по инициативе Заказчика',
      costs: {
        standart: 1500,
        expert: 1500,
        business: 1500,
        premium: 1500,
      }
    },
    {
      id: 'sfr-refund',
      name: 'Возмещение СФР по сотрудникам',
      cost: 0,
      tariff: 30,
      tariffs: {
        standart: 30,
        expert: 20,
        business: 0,
        premium: 0,
      },
      suggestion: 'которые трудоустроены в период, когда Заказчик не сопровождался у Исполнителя',
      full: 'Возмещение СФР по сотрудникам, которые трудоустроены в период, когда Заказчик не сопровождался у Исполнителя',
      costs: {
        standart: 0,
        expert: 0,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'employment-relationship',
      name: 'Оформление трудовых отношений на предприятии',
      cost: 3000,
      suggestion:
        'на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя',
      full: 'на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя',
      costs: {
        standart: 2000,
        expert: 2000,
        business: 2000,
        premium: 0,
      },
      free: [
        ['expert', 1],
        ['business', 5],
      ]
    },
    {
      id: 'preparation-for-labor-inspection',
      name: 'Подготовка предприятия к проверке трудовой инспекцией',
      suggestion: 'анализ имеющихся документов, подготовка недостающих документов',
      cost: 10000,
      full: 'Подготовка предприятия к проверке трудовой инспекцией (анализ имеющихся документов, подготовка недостающих документов)',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 0,
      },
    },
    {
      id: 'government-requests-preparation',
      name: 'Подготовка ответов на требования от гос органов',
      cost: 2000,
      maxCoef: 6,
      suggestion:
        'когда Заказчик не сопровождался у Исполнителя, без сдачи корректирующей отчетности',
      full: 'Подготовка ответов на Требования от государственных органов за период, когда Заказчик не сопровождался у Исполнителя, без сдачи корректирующей отчетности * зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 5000,
        premium: 0,
      },
      free: [
        ['business', 3],
      ]
    },
    {
      id: 'staff-schedule-changes',
      name: 'Утверждение/внесение изменений в штатное расписание',
      cost: 4000,
      maxCoef: 6,
      suggestion:
        'когда Заказчик не сопровождался у Исполнителя, а так же в случае, если Заказчиком был утвержден проект штатного расписания и Исполнитель принял в работу',
      full: 'Утверждение/внесение изменений в штатное расписание за периоды, когда Заказчик не сопровождался у Исполнителя, а так же в случае, если Заказчиком был утвержден проект штатного расписания и Исполнитель принял в работу\r\n* в зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6\r\n**шаг – 1 000 ₽ за каждые 10 штатных должностей',
      costs: {
        standart: 6000,
        expert: 6000,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'job-descriptions',
      name: 'Должностные инструкции для сотрудников',
      cost: 2000,
      maxCoef: 6,
      suggestion: 'трудоустроенных до начала периода обслуживания у Исполнителя',
      full: 'Должностные инструкции для сотрудников, трудоустроенных до начала периода обслуживания у Исполнителя\r\n* в зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 2000,
        expert: 2000,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'additional-agreements',
      name: 'Дополнительные соглашения к трудовым договорам, приказы',
      cost: 500,
      suggestion: 'которые необходимо было оформить до начала периода обслуживания у Исполнителя',
      full: 'Дополнительные соглашения к трудовым договорам, приказы, которые необходимо было оформить до начала периода обслуживания у Исполнителя',
      costs: {
        standart: 500,
        expert: 500,
        business: 500,
        premium: 0,
      },
      free: [
        ['business', 10],
      ]
    },
    {
      id: 'employee-data-corrections',
      name: 'Корр-ки в личные данные сотрудников',
      cost: 0,
      suggestion:
        'если сотрудники были приняты до начала периода обслуживания (внесение детей, семейного положения, образование и т.д.) по инициативе Заказчика',
      full: 'Внесение корректировок в личные данные сотрудников, если сотрудники были приняты до начала периода обслуживания (внесение детей, семейного положения, образование и т.д.) по инициативе Заказчика',
    },
    {
      id: 'document-check',
      name: 'Проверка кадровых документов',
      cost: 500,
      suggestion:
        'трудовые договоры, дополнительные соглашения, оформленные в периоды до начала обслуживания у Исполнителя',
      full: 'Проверка кадровых документов (трудовые договоры, дополнительные соглашения) оформленные в периоды до начала обслуживания у Исполнителя',
      costs: {
        standart: 500,
        expert: 500,
        business: 500,
        premium: 0,
      },
    },
    {
      id: 'regulatory-check',
      name: 'Проверка нормативно-правовых актов клиента',
      cost: 500,
      maxCoef: 6,
      suggestion:
        'Подготовленных самим клиентом до начала периода обслуживания у Исполнителя (Правила внутреннего трудового распорядка, Положение об оплате труда, Штатное расписание, Положение об отпусках, Положение о командировках, Положение о премировании и т.д.)',
      full: 'Проверка нормативно-правовых актов клиента, подготовленных самим клиентом до начала периода обслуживания у Исполнителя (Правила внутреннего трудового распорядка, Положение об оплате труда, Штатное расписание, Положение об отпусках, Положение о командировках, Положение о премировании и т.д.)\r\n* в зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 500,
        expert: 500,
        business: 500,
        premium: 0,
      },
    },
    {
      id: 'military-registration',
      name: 'Воинский учет',
      cost: 1500,
      suggestion:
        'без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж.',
      full: 'Воинский учет (без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж).',
      costs: {
        standart: 1500,
        expert: 1500,
        business: 1500,
        premium: 0,
      },
    },
    {
      id: 'military-registration-docs',
      name: 'Документы для воинского учета',
      cost: 3500,
      full: 'Подготовка пакета документов для постановки на воинский учет',
      costs: {
        standart: 1500,
        expert: 1500,
        business: 1500,
        premium: 0,
      },
    },
    {
      full: 'Подготовка пакета документов по запросу военкомата (без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж) для клиентов, не оплачивающих услугу по ведению воинского учета',
      id: 'military-response',
      name: 'Подготовка пакета документов по запросу военкомата',
      suggestion: '(без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж) для клиентов, не оплачивающих услугу по ведению воинского учета',
      cost: 5000,
      costs: {
        standart: 3500,
        expert: 3500,
        business: 3500,
        premium: 0,
      }
    },
    {
      id: 'migration-registration',
      name: 'Миграционный учет',
      cost: 10000,
      suggestion:
        'без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж',
      full: 'Миграционный учет (без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж).',
      costs: {
        standart: 10000,
        expert: 10000,
        business: 10000,
        premium: 0,
      },
    },
    {
      id: 'personnel-accounting',
      name: 'Учет расчетов с персоналом по ОП',
      cost: 1500,
      suggestion: 'в том числе на Тарифах "Зарплата и Кадры"',
      full: 'Учет расчетов с персоналом по обособленным подразделениям, в том числе на Тарифах "Зарплата и Кадры"',
      costs: {
        standart: 1500,
        expert: 1500,
        business: 1500,
        premium: 0,
      },
    },
  ],
  "коммуникации с ГБА" : [
    {
      name: 'Индивидуальный чат в мессенджере',
      id: 'individual-chat',
      cost: 500,
      full: 'Индивидуальный чат в мессенджере',
      costs: {
        standart: 5000,
        expert: 5000,
        business: 0,
        premium: 0,
      }
    }
  ]
};
